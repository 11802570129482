$(document).ready(function () {
  // console.log(dataLayer)

  //test
  //modal
  //header pagination
  //toggle mobile menu
  //hide mobile menu on click anywhere besides the menu
  //accordion questions


  let isSend = false;

  // svg4everybody({});
  const mainShare = $('.hero__share .sharing__link');
  // console.log(mainShare)
  mainShare.on('click',function(e){

    window.history.pushState('', '', `/`)
  })

  //test
  let currentStep = 1;
  const button = $('.test__next-btn');
  const screens = $('[data-js-step]');
  let successCount = 0;
  // console.log(button)
  button.on('click', function (e) {
    e.preventDefault();
    if (currentStep < screens.length) {
      screens.hide();
      $(`[data-js-step="${++currentStep}"]`).show();
      console.log('if')
    } else {
      if (!isSend) {
        isSend = true;
        ym(56457604,'reachGoal','test_complete');
      }


      console.log('else')
      // let isActive = false;
      // $('.overlay').fadeIn(300);
      // isActive = !isActive;
      // disableBodyScroll(isActive, '.overlay')
      // $('html').addClass('fixed');
      const inputs = $('.quiz__answer input:checked');
      const arr = [];
      inputs.each(function (index, el) {
        arr.push($(el).val())
      });
      const filteredArr = arr.filter(item => item === 'A');
      const result = inputs.length / 2 < filteredArr.length; // true - A


      let isActive = false;
      // let param = '';
      if (result) {
        $('.overlay[data-js-results="A"]').fadeIn(300);
        // param = 'answer=A';

        // $('meta[property="og:title"]').remove();
        // $('head').append( '<meta property=og:title content="this is new">' );

        // $('meta[property="og:image"]').remove();
        // $('head').append( '<meta property=og:image content="http://demo.air-dev.agency/assets/img/answers_a.png">' );

      }
      else {
        // param = 'answer=B';
        $('.overlay[data-js-results="B"]').fadeIn(300);
        // $('meta[property="og:image"]').remove();
        // $('head').append( '<meta property=og:image content="http://demo.air-dev.agency/assets/img/answers_b.png">' );
      }
      // window.history.pushState('', '', `?${param}`)

      // $('.overlay').fadeIn(300);
      isActive = !isActive;
      disableBodyScroll(isActive, '.overlay')
      $('html').addClass('fixed');
    }



    // else if (currentStep == screens.length) {
    // $('.test__next-btn').on('click', function (e) {
    //   e.preventDefault();
    //   let isActive = false;
    //   $('.overlay').fadeIn(300);
    //   isActive = !isActive;
    //   disableBodyScroll(isActive, '.overlay')
    //   $('html').addClass('fixed');
    // })
    //
    // $('.modal').on('click', function (e) {
    //   e.stopPropagation()
    // })
    //
    // $('.overlay').click((e) => {
    //   $('.overlay').fadeOut(300)
    //   $('html').removeClass('fixed')
    // })
    //
    // $('.modal__close-button').click(() => {
    //   $('.overlay').fadeOut(300)
    //   $('html').removeClass('fixed')
    // })
    // }

  })
  $('.modal').on('click', function (e) {
    e.stopPropagation()
  })

  $('.overlay').click((e) => {
    $('.overlay').fadeOut(300)
    $('html').removeClass('fixed')
  })

  $('.modal__close-button').click((e) => {
    e.preventDefault();
    $('.overlay').fadeOut(300)
    $('html').removeClass('fixed')
  })


  //modal


  // $('.test__next-btn').on('click', function (e) {
  //   e.preventDefault();
  //   let isActive = false;
  //   $('.overlay').fadeIn(300);
  //   isActive = !isActive;
  //   disableBodyScroll(isActive, '.overlay')
  //   $('html').addClass('fixed');
  // })
  //
  // $('.modal').on('click', function (e) {
  //   e.stopPropagation()
  // })
  //
  // $('.overlay').click((e) => {
  //   $('.overlay').fadeOut(300)
  //   $('html').removeClass('fixed')
  // })
  //
  // $('.modal__close-button').click(() => {
  //   $('.overlay').fadeOut(300)
  //   $('html').removeClass('fixed')
  // })


  //header pagination

  // Cache selectors
  var lastId,
      topMenu = $('.nav'),
      // wrapperTopMenu = $('.letters'),
      topMenuHeight = topMenu.outerHeight() + 1,
      distanceFromTop = topMenu.offset().top,

      // All list items
      menuItems = topMenu.find('.ref'),
      // Anchors corresponding to menu items
      scrollItems = menuItems.map(function () {
        let item = $(`[data-js-id=${$(this).attr('href').replace('#','')}]`);
        if (item.length) {
          return item;
        }
      });


  // console.log(menuItems)
// Bind click handler to menu items
// so we can get a fancy scroll animation
  menuItems.click(function (e) {
    var href = $(this).attr('href'),
        offsetTop = href === '#' ? 0 : $(`[data-js-id=${href.replace('#','')}]`).offset().top - topMenuHeight + 2;
    $('html, body').stop().animate({
      scrollTop: offsetTop
    }, 850);
    e.preventDefault();
  });

  let previousSection = '';

  $(window).scroll(function () {

    // Get container scroll position (px from top to change the letter color)
    var fromTop = $(this).scrollTop() + topMenuHeight + 150;

    // Get id of current scroll item

    // console.log(scrollItems)
    let cur = scrollItems.map(function () {
      if ($(this).offset().top < fromTop)
        return this;
    });


    let currentSection = $(cur[cur.length - 1]).attr('data-js-id');
    if (currentSection && previousSection != currentSection) {
      previousSection = currentSection;
      // previousSection = currentSection;
      // сюда отправлять событие
      window.location.hash = previousSection
    }


    // Get the id of the current element
    cur = cur[cur.length - 1];
    var id = cur && cur.length ? $(cur[0]).attr('data-js-id') : '';


    if (lastId !== id) {
      lastId = id;
      // Set/remove active class
      menuItems
          .parent().removeClass('active')
          .end().filter('[href="#' + id + '"]').parent().addClass('active');
    }
  });

  //toggle mobile menu
  $('.nav__mobile-menu').on('click', function (e) {
    $('.mobile-menu').toggleClass('active');
    $('.nav__list').toggleClass('active');
  });

  //hide mobile menu on click anywhere besides the menu
  $(document).on('click', function (e) {
    if (!$(e.target).hasClass('nav__mobile-menu') && !(e.target).closest('.nav__mobile-menu')) {
      $('.mobile-menu').removeClass('active');
      $('.nav__list').removeClass('active');
    }
  });

  // $('.header__nav').on('click', function (e) {
  //   e.stopPropagation();
  // });


  //accordion questions
  (function ($) {
    var tSlide = 250;
    var isAnimated = false;


    var $arrows = $('.question__trigger');

    let wiWidth = $(window).width();

    $(document).on('click', '.accordion__action', function () {
      const $allPanels = $('.accordion__sliding-part');
      const $parent = $(this).parent();
      const $target = $(this).next('.accordion__sliding-part');
      const $arrow = $(this).children('.question__trigger');

      if (!isAnimated) {
        isAnimated = true;
        if (wiWidth < 481) {
          $parent.toggleClass('active');
          $target.slideToggle();
          $arrow.toggleClass('active');
          // console.log('if');
          isAnimated = false;
        } else {
          // console.log('else');
          if (!$parent.hasClass('active')) {

            $allPanels.parent().removeClass('active');
            if (wiWidth > 860) {
              $('.question__decor').fadeOut(100)
              $('.question__decor-bot').fadeOut(100)
            }

            $allPanels.slideUp(tSlide, function () {
              isAnimated = false;
            });
            $arrows.removeClass('active');

            $parent.addClass('active');
            $arrow.addClass('active');
            $target.slideDown(tSlide, function () {

              if (wiWidth > 860) {
                if ($parent.hasClass('bottom-squares')) {
                  $('.question__decor').fadeIn(300)
                }

                if ($parent.hasClass('right-squares')) {
                  $('.question__decor-bot').fadeIn(300)
                }
              }
            });

          } else {

            if (wiWidth > 860) {
              if ($parent.hasClass('bottom-squares')) {
                $('.question__decor').fadeOut(100)
              }

              if ($parent.hasClass('right-squares')) {
                $('.question__decor-bot').fadeOut(100)
              }
            }
            $parent.removeClass('active');
            $arrow.removeClass('active');
            $target.slideUp(tSlide, function () {
              isAnimated = false;
            });

          }
        }


      }
    });

  })(jQuery);

});


var disableBodyScroll = (function () {

  var _selector = false,
      _element = false,
      _clientY;

  if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;

  if (!Element.prototype.closest)
    Element.prototype.closest = function (s) {
      var ancestor = this;
      if (!document.documentElement.contains(el)) return null;
      do {
        if (ancestor.matches(s)) return ancestor;
        ancestor = ancestor.parentElement;
      } while (ancestor !== null);
      return el;
    };


  var preventBodyScroll = function (event) {
    if (false === _element || !event.target.closest(_selector)) {
      event.preventDefault();
    }
  };

  var captureClientY = function (event) {
    // only respond to a single touch
    if (event.targetTouches.length === 1) {
      _clientY = event.targetTouches[0].clientY;
    }
  };

  var preventOverscroll = function (event) {
    // only respond to a single touch
    if (event.targetTouches.length !== 1) {
      return;
    }

    var clientY = event.targetTouches[0].clientY - _clientY;

    // The element at the top of its scroll,
    // and the user scrolls down
    if (_element.scrollTop === 0 && clientY > 0) {
      event.preventDefault();
    }

    // The element at the bottom of its scroll,
    // and the user scrolls up
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
    if ((_element.scrollHeight - _element.scrollTop <= _element.clientHeight) && clientY < 0) {
      event.preventDefault();
    }

  };

  /**
   * Disable body scroll. Scrolling with the selector is
   * allowed if a selector is porvided.
   *
   * @param  boolean allow
   * @param  string selector Selector to element to change scroll permission
   * @return void
   */
  return function (allow, selector) {
    if (typeof selector !== "undefined") {
      _selector = selector;
      _element = document.querySelector(selector);
    }

    if (true === allow) {
      if (false !== _element) {
        _element.addEventListener('touchstart', captureClientY, false);
        _element.addEventListener('touchmove', preventOverscroll, false);
      }
      document.body.addEventListener("touchmove", preventBodyScroll, false);
    } else {
      if (false !== _element) {
        _element.removeEventListener('touchstart', captureClientY, false);
        _element.removeEventListener('touchmove', preventOverscroll, false);
      }
      document.body.removeEventListener("touchmove", preventBodyScroll, false);
    }
  };
}());
